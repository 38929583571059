import React from "react";
import Layout from "../components/layout";
import Head from "../components/head";
import { graphql } from "gatsby";
import handleSubmission from "../utils/handleSubmission";

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const isBrowser = () => typeof window !== "undefined";
const url = isBrowser() ? window.location.href : "";

const usp = [
  {
    title:
      "Learn where most companies fail at implementing OKRs and how to avoid it for yourself.",
    id: "1",
  },
  {
    title:
      "Experience the major mindset shift when moving from output-focused goals to outcome-focused goals.",
    id: "2",
  },
  {
    title:
      "See how to take your big objectives and turn them into day-to-day tasks that are easy to complete.",
    id: "3",
  },
];

const okrCoursePage = (props) => {
  return (
    <Layout>
      <Head
        title={`OKR Course - From 0 To OKR Hero | OKR Framework`}
        description={`Learn the most important parts of OKRs before getting started with the framework. We cover the do's and dont's and provide examples of great OKRs.`}
        url={`${props.data.site.siteMetadata.siteUrl}/okr-course/`}
      />

      <main className="bg-white text-slate-800 mx-auto px-4 sm:px-6 lg:px-8 py-10 sm:py-12 md:py-16 lg:py-20  xl:py-28">
        <div className="sm:text-center">
          <h1 className="text-4xl sm:text-5xl md:text-6xl flex flex-col-reverse font-bold tracking-tight">
            From 0 To OKR Hero{" "}
            <span className="text-lg text-slate-800 font-bold tracking-normal mb-2 sm:mb-4">
              OKR Course
            </span>
          </h1>
          <p className="mt-4 sm:mt-6 xl:mt-8 text-base sm:mx-auto sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-auto">
            This 3-step OKR email course will teach you the 3 key things you
            need to know before getting started with OKRs.
          </p>
        </div>
        <div className="flex flex-col sm:mx-auto sm:max-w-md mt-4">
          {usp.map((item) => {
            return (
              <div
                key={item.id}
                className="flex flex-row flex-shrink-0 flex-grow-0 mt-4"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="min-w-12 min-h-12 w-12 h-12 text-indigo-800 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    className="bg-indigo-800"
                  />
                </svg>
                <p className="text-slate-800">{item.title}</p>
              </div>
            );
          })}
        </div>
        <form
          className="mt-8 sm:mt-10 lg:mt-12 mx-auto flex flex-col sm:flex-row w-full justify-center"
          action="/signup-complete/"
          onSubmit={handleSubmission}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="url" value={url} />
          <input
            placeholder="name@email.com"
            type="email"
            id="email"
            name="email"
            required
            className="sm:flex items-center w-full sm:w-72 text-left space-x-3 px-4 h-12 sm:h-14 bg-slate-100 ring-1 ring-slate-900/20 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-sm rounded-lg placeholder:text-slate-400 text-slate-800"
          />
          <button
            type="submit"
            className="bg-indigo-500 hover:bg-indigo-700 ring-1 ring-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-indigo-50 text-white font-semibold h-14 px-6 sm:ml-4 mt-4 sm:mt-0 rounded-lg w-full flex items-center justify-center sm:w-auto"
          >
            Start course
          </button>
        </form>
      </main>
    </Layout>
  );
};

export default okrCoursePage;
