const handleSubmission = (e) => {
  e.preventDefault();

  const data = {
    url: e.target.url.value,
    email: e.target.email.value,
    created_at: new Date(),
  };

  fetch(`${"https://hook.eu1.make.com/dk6f3q1t9hajnb34jr0ywwqcvrjkixdn"}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("Success:", data);
      window.location.href = "/signup-complete/";
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export default handleSubmission;
